<template>
  <div>
    <v-divider></v-divider>
    <v-row no-gutters>
      <v-col>
        <div class="mt-2 mb-2">
          <div
            v-if="
              typeof subTable.data != 'undefined' && subTable.data.length > 0
            "
          >
            <configContainer :title="tableTitle">
              <template v-slot:content>
                <tableView
                  :currentTable="currentTable"
                  :result="result"
                ></tableView>
              </template>
            </configContainer>
          </div>
          <primaryButton
            :label="'Add ' + tableTitle"
            icon="mdi-plus"
            @click="addLdapContent()"
            type="button"
            color="primary"
            :disabled="disabled"
          ></primaryButton>
          <addField
            :path="addingURL"
            :additionalAttributes="additionalAttributes"
            :dialogAttributes="{}"
            :showDialog="openDialogFlag"
            @dialog-closed="openDialogFlag = false"
          ></addField>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
import tableView from "@/commonComponents/tableView.vue";
import addField from "@/commonComponents/addField.vue";
import configContainer from "@/commonComponents/configContainer.vue";
import requests from '@/js/requests';
export default {
  data() {
    return {
      openDialogFlag: false,
      data: {},
      subscriberId: "",
      tablesData: this.$store.state.tablesData,
    };
  },
  props: ["result", "addingURL", "calledFunction", "tableTitle", "subTable"],
  components: { tableView, primaryButton, addField, configContainer },
  created() {
    this.$store.commit("tableChanged", this.subTable);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "tableChanged") {
        console.log("mutated", state);
        this.tablesData = state.tablesData;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  computed: {
    disabled() {
      let disabled = false;
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
    additionalAttributes() {
      return {
        function: this.calledFunction,
        n_subscriberId: this.subscriberId,
        removeSubscriber: 1,
      };
    },
    currentTable: {
      get() {
        return this.tablesData[this.subTable.id];
      },
      set(tableValue) {
        this.$emit("table-changed", tableValue);
      },
    },
  },
  methods: {
    addLdapContent() {
      //  var $caller = $(caller);
      // var $fieldsObject = $caller.closest(".fields");
      console.log(this.result);
      //  console.log(this.$refs.form.validate(), this.isValid);
      let formRefObject = this.result.formRefObject;
      if (formRefObject.validate()) {
        //if ($fieldsObject.valid()) {
        //First validate
        this.subscriberId = this.result.valueLine.n_id;
        if (!this.subscriberId) {
          let form = formRefObject.$el;
          var bodyFormData = new FormData(form);

          bodyFormData.set("function", "add");
          let path = this.result.tableUrl;
          let caller = this;

          requests.frameworkAxiosRequest({
            method: 'post',
            url: path,
            data: bodyFormData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
              .then(function (response) {
              console.log(response);
              let data = response.data.result.json;
              caller.data = data;
              caller.subscriberId = data.addedIndex;
              if (data.addedIndex != "-1") {
                // after once add the button would act like edit!
                caller.$set(
                  caller.result.fields.n_id,
                  "value",
                  data.addedIndex
                );
                // $fieldsObject.find("[name='n_id']").val(data.addedIndex);
                caller.$set(
                  caller.result.fields.n_id_key,
                  "value",
                  data.addedIndexKey
                );

                caller.$set(caller.result.function, 0, {
                  functionName: "Save",
                  function: "edit",
                });
                caller.openDialogFlag = true;
              }
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });
        } else {
          this.openDialogFlag = true;
        }
      }
    },
  },
};
</script>